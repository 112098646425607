<template>
    <div :class="size">
        <v-card>
            <template v-if="size === 'xs'">
                <!-- Small card -->
                <v-card-title>
                    Trello    
                    <v-spacer></v-spacer>
                    <v-chip class="ma-1 d-inline-block" style="font-weight:bold;color:white" label small color="red">Urgent</v-chip>
                    <v-chip class="ma-1 d-inline-block" style="font-weight:bold;color:white" label small color="orange">Bug</v-chip>   
                </v-card-title>
                <div class="px-2" @click="() => cardDialog = true">
                    <div class="text--secondary caption">
                        Mettre à jour le site e-commerce et ajouter le paiement en 3 fois.
                    </div>
                    <div class="d-flex mt-2" style="align-items:center;">
                        <v-icon small class="mr-2">mdi-menu</v-icon>
                        <div style="align-self:center;">
                            <v-icon small>mdi-message-outline</v-icon> <small>13</small>
                        </div>
                        <v-chip color="green" style="color:white" class="px-1 mx-2" label small><v-icon class="pr-2" small>mdi-checkbox-marked-outline</v-icon>10/10</v-chip>
                        <v-spacer></v-spacer>
                        <v-avatar color="primary" class="mr-2" style="color:white" size="40">JD</v-avatar>
                        <v-avatar color="primary" class="mr-2" style="color:white" size="40">EF</v-avatar>
                        <v-avatar color="primary" class="mr-2" style="color:white" size="40">EB</v-avatar>
                    </div>
                    <div class="float-right">
                        
                    </div>
                </div>
            </template>
            <template v-else-if="size === 'md'">
                    <v-card-title>
                        Trello
                    </v-card-title>
                    <div class="wrapper">
                        <v-card class="scrollbar_thumb pa-2 mb-4 mr-2" @click="() => cardDialog = true" v-for="i in 6" :key="'Trello-'+ i">
                            <div style="display:inline-block">
                                <v-chip class="ma-1 d-inline-block" style="font-weight:bold;color:white" label small color="red">Urgent</v-chip>
                                <v-chip class="ma-1 d-inline-block" style="font-weight:bold;color:white" label small color="orange">Bug</v-chip>
                            </div>
                            <div class="py-2">
                                Mettre à jour le site e-commerce et ajouter le paiement en 3 fois.
                            </div>
                            <div class="d-flex py-2" style="align-items-center">
                                <v-icon small class="mr-2">mdi-menu</v-icon>
                                <div style="align-self:center;">
                                    <v-icon small>mdi-message-outline</v-icon> <small>13</small>
                                </div>
                                <v-chip color="green" style="color:white" class="px-1 mx-2" label small><v-icon class="pr-2" small>mdi-checkbox-marked-outline</v-icon>10/10</v-chip>
                            </div>
                            <div>
                                <v-avatar color="primary" class="mr-2" style="color:white" size="50">JD</v-avatar>
                                <v-avatar color="primary" class="mr-2" style="color:white" size="50">EF</v-avatar>
                                <v-avatar color="primary" class="mr-2" style="color:white" size="50">EB</v-avatar>
                            </div>
                        </v-card>
                    </div>
            </template>
            <template v-else-if="size === 'xl' || size === 'xmd'">
                <v-card-title>
                    Trello
                </v-card-title>
                <WrapperHorizontal :showBtn="false" style="max-height:100%; display:contents">
                    <div class="List pb-5" v-for="i in 3" :key="i">
                        <v-card class="mr-4">
                            <v-card-title>
                                Idées
                            </v-card-title>
                            <div class="wrapper pa-1">
                                <v-card class="pa-2 mb-2 mr-1" elevation="4" @click="() => cardDialog = true" v-for="i in 6" :key="'Trello-'+ i">
                                    <div style="display:inline-block">
                                        <v-chip class="ma-1 d-inline-block" style="font-weight:bold;color:white" label small color="red">Urgent</v-chip>
                                        <v-chip class="ma-1 d-inline-block" style="font-weight:bold;color:white" label small color="orange">Bug</v-chip>
                                    </div>
                                    <div class="py-2">
                                        Mettre à jour le site e-commerce et ajouter le paiement en 3 fois.
                                    </div>
                                    <div class="d-flex py-2" style="align-items-center">
                                        <v-icon small class="mr-2">mdi-menu</v-icon>
                                        <div style="align-self:center;">
                                            <v-icon small>mdi-message-outline</v-icon> <small>13</small>
                                        </div>
                                        <v-chip color="green" style="color:white" class="px-1 mx-2" label small><v-icon class="pr-2" small>mdi-checkbox-marked-outline</v-icon>10/10</v-chip>
                                    </div>
                                    <div>
                                        <v-avatar color="primary" class="mr-2" style="color:white" size="50">JD</v-avatar>
                                        <v-avatar color="primary" class="mr-2" style="color:white" size="50">EF</v-avatar>
                                        <v-avatar color="primary" class="mr-2" style="color:white" size="50">EB</v-avatar>
                                    </div>
                                </v-card>
                            </div>
                        </v-card>
                    </div>
                </WrapperHorizontal>
            </template>
            <!-- Cards dialog -->
            <v-dialog v-model="cardDialog" width="800">
                <v-card style="max-height: 80% !important;">
                    <v-btn icon class="float-right" @click="() => cardDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-card-title class="headline mb-4">
                        Mettre à jour le site e-commerce et ajouter le paiement en 3 fois.
                    </v-card-title>

                    <v-card-subtitle>
                        <div class="d-inline-block mr-3">
                            MEMBRES
                            <div class="d-block">
                                <v-avatar color="primary" class="mr-2" size="40">JD</v-avatar>
                                <v-avatar color="primary" class="mr-2" size="40">EF</v-avatar>
                                <v-avatar color="primary" class="mr-2" size="40">EB</v-avatar>
                            </div>
                        </div>
                        <div class="d-inline-block mr-3" style="vertical-align:top">
                            &Eacute;TIQUETTES
                            <div class="d-block">
                                <v-chip class="ma-1 d-inline-block" label small color="red">Urgent</v-chip>
                                <v-chip class="ma-1 d-inline-block" label small color="orange">Bug</v-chip>
                            </div>
                        </div>
                        
                    </v-card-subtitle>
                    
                    <v-card-text>
                        <h4 class="mb-2">DESCRIPTION</h4>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </v-card-text>

                    <v-divider></v-divider>

                    <div class="ma-4">
                        <v-icon class="mr-2">mdi-checkbox-marked-outline</v-icon> 
                        <span>Nom checkList</span>
                    </div>
                    <div class="mx-4 d-flex" style="align-items:center">
                        <span class="d-inline-block">100%</span>
                        <v-progress-linear class="d-inline-block mx-2" color="green" value="100" rounded> </v-progress-linear>
                    </div>
                    <div class="mx-4 d-flex" style="align-items:center" v-for="i in 10" :key="'Task-' + i">
                        <v-checkbox v-model="checked" readonly></v-checkbox>
                        <span>Tâche n°{{ i }}</span>
                    </div>

                    <v-divider></v-divider>
                    
                    <div class="ma-4"> 
                        <v-icon class="mr-2">mdi-format-list-text</v-icon> 
                        <span>Activité</span>
                    </div>
                    <div class="mx-4 d-flex"  v-for="i in 10" :key="'Comment-' + i">
                        <v-avatar color="primary" size="40" class="mx-2">JD</v-avatar>
                        <div>
                            <h4 class="d-inline-block mr-3">John DOE</h4>
                            <small>Hier à 14:30</small>
                            <v-text-field readonly value="This is a comment" outlined dense></v-text-field>
                        </div>
                    </div>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="secondary" text @click="cardDialog = false">
                            Fermer
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
    </div>
</template>

<script>
import WrapperHorizontal from '../WrapperHorizontal.vue';

export default {
    name : 'Trello',
    components : {
        WrapperHorizontal
    },
    props : {
        size : {
            type : String,
            default : 'md'
        },
    },
    data() {
        return {
            cardDialog : false,
            checked : true,
        }
    }
}
</script>

<style lang="scss">
.v-dialog {
    max-height: 85% !important;
}
</style>

<style lang="scss" scoped>

::v-deep .wrapper-cards {
    height: 100%;

    > .List { 
        height: 100%;
        position: relative;
        display : inline-block;
        margin: 0.75rem;
        
        > .v-card {
            width: 400px;
            overflow: hidden;
            position: relative;
            display : flex;
            flex-direction: column;
            background-color: #3d4759;
            margin: 0.75rem;
            height: 100%;
            padding: 0 10px 10px 10px;
        }
    }    
}


.v-chip {
    max-width: max-content !important;
    display: inline-block !important;
}

.widget {
    @media screen and (max-width: 600px) {
        grid-column: span 2;
        grid-row: span 5;
    }
    @media screen and (min-width: 600px) {
        grid-column: span 2;
        grid-row: span 6;
    }
    @media screen and (min-width: 960px) {
        grid-column: span 3;
        grid-row: span 2;
    }
    @media screen and (min-width: 1200px) {
        &.xs {
            grid-column: span 1;
            grid-row: span 2;
        }
        &.sm {
            grid-column: span 2;
            grid-row: span 4;
        }
        &.md {
            grid-column: span 2;
            grid-row: span 6;
        }
        &.lg {
            grid-column: span 4;
            grid-row: span 4;
        }
        &.xl {
            grid-column: span 4;
            grid-row: span 6;
        }
        &.xmd {
            grid-column: span 8;
            grid-row: span 6;
        }
    }
}
</style>